<script setup>
import { onMounted, ref } from "vue"
import { useCourseStore } from "@/store/courses/course"

import MemberAvatarNameWithTag from "@/components-uhas/members/MemberAvatarNameWithTag.vue"
import MemberPhone from "../../../components-uhas/members/MemberPhone.vue"

const courseStore = useCourseStore()

const memberCourseExpires = ref([])
const loading = ref(true)
const totalRecords = ref(0)
const txtSearch = ref(null)

const courseSelected = ref('all')
const optionCourses = [
	{ title: "All Course", id: 'all' },
]

const isExpire = ref('all')
const optionExpire = [
	{ name: "Expiration", value: 'all' },
	{ name: "Not Expire", value: 0 },
	{ name: "Expired", value: 1 }
]

onMounted(() => {
	courseStore.index().then((res) => {
		optionCourses.push(...res.data.courses)
	})
})

const search = (e) => {
  loading.value = true

	const page = typeof e !== 'undefined' ? (e.page || 0) + 1 : 1
  courseStore.indexMember({ page, option: { course_id: courseSelected.value, is_expire: isExpire.value, txt_search: txtSearch.value } }).then((res) => {
    memberCourseExpires.value = res.data.member_course_expires.data
		totalRecords.value = res.data.member_course_expires.total

    loading.value = false
  })
}

const clearFilter = () => {
	txtSearch.value = null
	courseSelected.value = 'all'
	search()
}

search()
</script>

<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
				<h5>Courses Members Index</h5>
				<DataTable
					class="p-datatable-gridlines"
					dataKey="id"
					filterDisplay="row"
					:loading="loading"
					:lazy="true"
					:paginator="true"
					:rowHover="true"
					:rows="10"
					:totalRecords="totalRecords"
					:value="memberCourseExpires"
					@page="search($event)"
				>
					<template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
							<Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter" />
              <div class="col-12 md:col-6">
                <div class="p-inputgroup">
                  <InputText v-model="txtSearch" placeholder="Uhas UID, Phone" />
									<Dropdown v-model="courseSelected" optionLabel="title" optionValue="id" :options="optionCourses" />
									<Dropdown v-model="isExpire" optionLabel="name" optionValue="value" :options="optionExpire" />
                  <Button icon="pi pi-search" class="p-button-warning" @click="search" />
                </div>
              </div>
						</div>
          </template>
          <template #empty>
              No records.
          </template>
          <template #loading>
              Loading. Please wait.
          </template>

					<Column header="Uhas UID">
						<template #body="{data}">
							<keep-alive>
								<router-link :to="`/members/${data.member.id}`" target="_blank">
									{{ data.member.uhas_uid }}
								</router-link>
							</keep-alive>
						</template>
					</Column>

					<Column header="Name">
            <template #body="{data}">
							<MemberAvatarNameWithTag :member="data.member" />
            </template>
          </Column>

					<Column header="Phone">
            <template #body="{data}">
              <MemberPhone :phone="data.member.phone" />
            </template>
          </Column>

					<Column field="course.title" header="Course" />

					<Column header="Expire">
						<template #body="{data}">
							{{ data.expire_date ? new Date(data.expire_date).toLocaleDateString() : ''}}
						</template>
					</Column>

				</DataTable>
      </div>
    </div>
  </div>
</template>
